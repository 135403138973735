
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit {{readingList.title}} Reading List</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body {{utilityService.getActiveBreakpoint() === Breakpoint.Mobile ? '' : 'd-flex'}}">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="{{utilityService.getActiveBreakpoint() === Breakpoint.Mobile ? 'horizontal' : 'vertical'}}" style="min-width: 135px;">     
        <li [ngbNavItem]="tabs[0]">
            <a ngbNavLink>{{tabs[0].title}}</a>
            <ng-template ngbNavContent>
                <p>
                    This list is currently {{readingList?.promoted ? 'promoted' : 'not promoted'}} (<i class="fa fa-angle-double-up" aria-hidden="true"></i>). 
                    Promotion means that the list can be seen server-wide, not just for admin users. All series that are within this list will still have user-access restrictions placed on them.
                </p>
                <form [formGroup]="reviewGroup">
                    <div class="mb-3">
                        <label for="title" class="form-label">Name</label>
                        <input id="title" class="form-control" formControlName="title" type="text">
                    </div>
            
                    <div class="mb-3">
                        <label for="summary" class="form-label">Summary</label>
                        <textarea id="summary" class="form-control" formControlName="summary" rows="3"></textarea>
                    </div>
                </form>
            </ng-template>
        </li>
        <li [ngbNavItem]="tabs[1]">
            <a ngbNavLink>{{tabs[1].title}}</a>
            <ng-template ngbNavContent>
                <p class="alert alert-primary" role="alert">
                    Upload and choose a new cover image. Press Save to upload and override the cover.
                </p>
                <app-cover-image-chooser [(imageUrls)]="imageUrls" (imageSelected)="updateSelectedIndex($event)" (selectedBase64Url)="updateSelectedImage($event)" [showReset]="readingList.coverImageLocked" (resetClicked)="handleReset()"></app-cover-image-chooser>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="ms-2 mt-3"></div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
    <button type="button" class="btn btn-secondary alt" (click)="togglePromotion()">{{readingList.promoted ? 'Demote' : 'Promote'}}</button>
    <button type="submit" class="btn btn-primary" [disabled]="reviewGroup.get('title')?.value.trim().length === 0" (click)="save()">Save</button>
</div>


